import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { render } from "react-dom";
import { NestCamWiredStandSharp } from "@mui/icons-material";
// import { GetBranchCookie } from "user_interface/src/components/Header/HeaderGrid";

// import GetBranchCookie from "../../components/Header/HeaderGrid";
import Cookies from 'universal-cookie';

//import mockPost from "../../data/postData.json";
//import mockFirstRender from "../../data/testingFiles/1. Trapzilla Superautomaticmanoutofyou.json";
//import mockFirstRender from "../../data/testingFiles/31. Two page doc 1.json";
//import mockSecondRender from "../../data/testingFiles/32. Two page doc 2.json"
//import mockThirdRender from "../../data/dataThirdRender.json";

type sendFileType = {
    fileData: FormData;
    parsing_strategy: number;
    branch?: string;
    localTest: boolean;
    renderCounter: number;
};

type setBranchType = {
    branch: number;
};

type setUsernameType = {
    username: string;
};

type sendPostType = {
    searchData: string;
    localTest: boolean;
    data: any;
    id: any
};

type insertDataType = {
    renderCounter: number;
    insertData: any;
    oldData: any;
};


type selectModelType = {
    rows: any;
    id: any;
    valueSelected: any;
};
type resetDataType = {
    oldData: any;
}

type sendJsonType = {
    fileData: FormData;
    branch: string;
    username: string;
    //CREDENTIALS -> FOR THE MOEMENT THIS IS HARDCODED
};

type sendReportDataType = {
    summary: string;
    description: string;
    fileData: FormData;
};

type addRowType = {
    props: any;
    data: any;
    selectedId: string;
};

type changeQuantityType = {
    data: any;
    quantity: any;
    selectedId: any;
};

type changeTagType = {
    data: any;
    tag: any;
    selectedId: any;
};

type deleteRowType = {
    props: any;
    data: any;
    selectedId: string;
};

// TODO: THIS IS REPEATED CODE THAT SHOULD BE IMPORTED FROM HeaderGrid.tsx
function GetBranchCookie() {
    const cookies: any = new Cookies();
    const cookie: number = cookies.get('branch');
    if (cookie === undefined) {
        return 0 //'Estimating Office'
    } else {
        return cookie
    }
}

function GetUsernameCookie() {
    const cookies: any = new Cookies();
    const cookie: string = cookies.get('username');
    if (cookie === undefined) {
        return 0 //'Estimating Office'
    } else {
        return cookie
    }
}


export const changeTag = createAsyncThunk(
    "user/changeTag",
    async (tagContent: changeTagType, thunkAPI) => {
        try {
            let selectedId = tagContent.selectedId;
            let data = tagContent.data;
            let newTag = tagContent.tag;
            // filter deleteId
            let newRows = JSON.parse(JSON.stringify(data));
            var newTagData = [];
            for (var i = 0; i < newRows.length; i++) {
                let element = newRows[i];
                if (element.id === selectedId) {
                    const tagRow = newRows[selectedId]
                    tagRow['tag'] = newTag;
                    tagRow['model'] = '';
                    tagRow['quantity'] = ''
                    newTagData.push(tagRow);
                } else {
                    newTagData.push(element);
                }
            };
            return thunkAPI.fulfillWithValue({
                message: "CHANGE TAG MESSAGE",
                data: newTagData
            });

        } catch (err: any) {
            console.log(err);

            return thunkAPI.rejectWithValue({
                message:
                    "Error CHANGE TAG",
                code: err.response.status,
            });
        }
    }
);

export const changeQuantity = createAsyncThunk(
    "user/changeQuantity",
    async (quantityContent: changeQuantityType, thunkAPI) => {
        try {
            let selectedId = quantityContent.selectedId;
            let data = quantityContent.data;
            let newQuantity = quantityContent.quantity;
            //console.log(`quantityContent : ${quantityContent }`);

            // filter deleteId
            let newRows = JSON.parse(JSON.stringify(data));
            var newQtyData = [];

            for (var i = 0; i < newRows.length; i++) {
                let element = newRows[i];
                //console.log("ELEMNT: ", element);
                if (element.id === selectedId) {
                    const qtyRow = newRows[selectedId]
                    qtyRow['quantity'] = newQuantity;
                    newQtyData.push(qtyRow);
                } else {
                    newQtyData.push(element);
                }
            };
            return thunkAPI.fulfillWithValue({
                message: "CHANGE QUANTITY MESSAGE",
                data: newQtyData
            });

        } catch (err: any) {
            console.log(err);

            return thunkAPI.rejectWithValue({
                message:
                    "Error CHANGE QUANTITY",
                code: err.response.status,
            });
        }
    }
);

export const addRow = createAsyncThunk(
    "user/addRow",
    async (addContent: addRowType, thunkAPI) => {
        try {
            let selectedId = addContent.selectedId;
            let data = addContent.data;

            console.log(`nextId: ${selectedId + 1}`);
            const emptyRow = {
                id: selectedId + 1,
                tag: " ",
                manufacturer: [" "],
                model: [" "],
                quantity: 1,
                eclipse_id: " ",
                description: " ",
                descriptionIndex: 0

            };

            // filter deleteId
            let newRows = JSON.parse(JSON.stringify(data));
            var aumentedData = [];

            for (var i = 0; i < newRows.length; i++) {
                let element = newRows[i];
                //console.log("ELEMNT: ", element);
                if (element.id === selectedId) {
                    aumentedData.push(element);
                    aumentedData.push(emptyRow);
                } else if (element.id > selectedId) {
                    let new_element = Object.assign({}, element);
                    new_element['id'] = new_element['id'] + 1;
                    aumentedData.push(new_element);
                } else {
                    aumentedData.push(element);
                }
            };
            return thunkAPI.fulfillWithValue({
                message: "ADD ROW MESSAGE",
                data: aumentedData
            });

        } catch (err: any) {
            console.log(err);

            return thunkAPI.rejectWithValue({
                message:
                    "Error ADD ROW",
                code: err.response.status,
            });
        }
    }
);

export const reviewData = createAsyncThunk(
    "user/addRow",
    async (addContent: addRowType, thunkAPI) => {
        try {
            let selectedId = addContent.selectedId;
            let data = addContent.data;

            return thunkAPI.fulfillWithValue({
                message: "ADD ROW MESSAGE",
                data: 'aumentedData'
            });

        } catch (err: any) {
            console.log(err);

            return thunkAPI.rejectWithValue({
                message:
                    "Error ADD ROW",
                code: err.response.status,
            });
        }
    }
);

export const selectModel = createAsyncThunk(
    "user/selectModel",
    async (selectContent: selectModelType, thunkAPI) => {
        try {
            let newRows = JSON.parse(JSON.stringify(selectContent.rows));
            const modelValues = selectContent.rows[selectContent.id]["model"];
            const indexSelection = modelValues.indexOf(selectContent.valueSelected);
            //newRows[selectContent.id]["descriptionIndex"] = indexSelection;
            var { newModels, newEclipseid, newDescription, newManufacturer } = changeOrder(newRows[selectContent.id], indexSelection);
            newRows[selectContent.id]["model"] = newModels;
            newRows[selectContent.id]["description"] = newDescription;
            newRows[selectContent.id]["eclipse_id"] = newEclipseid;
            newRows[selectContent.id]["manufacturer"] = newManufacturer;

            return thunkAPI.fulfillWithValue({
                message: "Data has been updated and a model was selected.",
                data: newRows
            });

        } catch (err: any) {
            console.log(err);
            var message = "Error: SELECT MODEL: " + err
            return thunkAPI.rejectWithValue({
                message: message,
                code: err.response.status,
            });
        }
    }
);


export const deleteRow = createAsyncThunk(
    "user/deleteRow",
    async (deleteContent: deleteRowType, thunkAPI) => {
        try {
            let selectedId = deleteContent.selectedId;
            let data = deleteContent.data;
            console.log(`nextId: ${selectedId + 1}`);
            // filter deleteId
            let newRows = JSON.parse(JSON.stringify(data));
            var reducedData = [];

            for (var i = 0; i < newRows.length; i++) {
                let element = newRows[i];
                //console.log("ELEMNT: ", element);
                if (element.id > selectedId) {
                    let new_element = Object.assign({}, element);
                    new_element['id'] = new_element['id'] - 1;
                    //console.log(`new_element.id: ${new_element.id}`);
                    reducedData.push(new_element);
                } else if (element.id < selectedId) {
                    reducedData.push(element);
                }
            };
            console.log("reducedData: ", JSON.stringify(reducedData));


            return thunkAPI.fulfillWithValue({
                message: "DELETE ROW MESSAGE",
                data: reducedData
            });

        } catch (err: any) {
            var message = "Error: DELETE ROW : " + err;
            return thunkAPI.rejectWithValue({
                message: message,
                code: err.response.status,
            });
        }
    }
);
function reorderList(gridList: any, selectedIndex: number) {
    var selectedModel = gridList[selectedIndex]
    var initialValue = gridList[0]
    var copyList = [...gridList]
    copyList[selectedIndex] = initialValue
    copyList[0] = selectedModel
    return copyList
}

function changeOrder(data: any, selectedIndex: any) {
    var models = data['model'];
    var eclipseid = data['eclipse_id'];
    var description = data['description'];
    var manufacturer = data['manufacturer'];

    var newModels = reorderList(models, selectedIndex)
    var newEclipseid = reorderList(eclipseid, selectedIndex)
    var newDescription = reorderList(description, selectedIndex)
    var newManufacturer = reorderList(manufacturer, selectedIndex)

    return { newModels, newEclipseid, newDescription, newManufacturer }
}

function encodeEclipse(data: any) {
    var eclipse_list = [];
    var model_values = [];
    var descriptionIndex = '';
    var model = '';
    var eclipseid = '';
    var manufacturer = '';
    var json_row = {};
    var manufacturer_values = [];
    var eclipseid_values = [];
    var quantity_value = 1;
    var data_row = [];

    for (var i = 0; i < data.length; i++) {
        data_row = data[i];
        //TODO: 'TEST if quantity for tag works'
        model_values = data_row['model']
        quantity_value = data_row['quantity']
        manufacturer_values = data_row['manufacturer']
        descriptionIndex = data_row['descriptionIndex']
        eclipseid_values = data_row['eclipse_id']
        //console.log('model_values', model_values);
        if (model_values.length > 1) {
            console.log(`WARNING:   Data passed without selecting element from Dropdown component ${model_values} `);
            model = model_values[descriptionIndex];
            manufacturer = manufacturer_values[descriptionIndex];
            eclipseid = eclipseid_values[descriptionIndex];

        } else if (model_values.length === 0) {
            model = '';
            manufacturer = '';
            eclipseid = '';
        } else {
            model = model_values[0] //set model to string
            manufacturer = manufacturer_values[0];
            eclipseid = eclipseid_values[0];
        }
        json_row = {
            'TAG': data_row['tag'],
            'MANF': manufacturer,
            'ECLIPSE_ID': eclipseid,
            'MODEL': model,
            'QUANTITY': quantity_value,

        }
        eclipse_list.push(json_row)
    }
    console.log('\n\n xxxxxx encodeEclipse/eclipse_list: ', eclipse_list);
    localStorage.setItem("dataEclipse", JSON.stringify(eclipse_list));

    return eclipse_list
}

function decodeEclipse(eclipseOutput: any) {
    var eclipseOutputFormated = eclipseOutput.replace('\"', '');
    eclipseOutputFormated = eclipseOutputFormated.replace('"', '');

    var eclipseLabel = "Order Submitted. Bid ID: " + eclipseOutputFormated;
    //copyTextToClipboard(eclipseOutputFormated)
    console.log(`decodeEclipse/eclipseLabel: ${eclipseLabel}`);
    return eclipseLabel
}

export const sendFileEclipse = createAsyncThunk(
    "user/sendFileEclipse",
    async (data: sendJsonType, thunkAPI) => {
        var FRONT_DEVELOPMENT = true;
        var process_connection = FRONT_DEVELOPMENT ? "https://scouttest.reece.com/api" : `${process.env.REACT_APP_API_URL}`;
        console.log("sendFileEclipse/data.branch:", data.branch)
        console.log("sendFileEclipse/data.username:", data.username)

        const eclipseData: any = encodeEclipse(data.fileData);
        console.log(`eclipseData: ${JSON.stringify(eclipseData)}`);
        try {
            const response = await axios.post(
                `${process_connection}/eclipse_submit`,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    params: {
                        file: eclipseData,
                        branch: data.branch,
                        username: data.username
                    },
                });
            console.log('*** response ', response)
            var orderId = response.data.message;
            var status_code = response.data.status_code;
            if (status_code < 500) {
                //NOT AN ERROR
                console.log(`UAC/sendFileEclipse/orderId: ${JSON.stringify(orderId)}`);
                if (orderId.errorMessage !== undefined) {
                    return thunkAPI.rejectWithValue({
                        message: "Error sending information to ECLIPSE. Please review your schedule",
                        code: status_code,
                        eclipseData: eclipseData

                    });
                }

                var eclipseLabel = decodeEclipse(orderId)
                return thunkAPI.fulfillWithValue({
                    eclipseLabel: eclipseLabel,
                    eclipseData: eclipseData,
                });

            } else {
                return thunkAPI.rejectWithValue({
                    message: orderId,
                    code: status_code,
                    eclipseData: eclipseData,
                });
            }


        } catch (err: any) {
            console.log(err);
            var errorMessage: string = "File not submitted: An Eclipse ID is required to submit a bid. Please input your Eclipse ID and then re-submit your bid to Eclipse.";
            return thunkAPI.rejectWithValue({
                message: errorMessage,
                code: err.response.status,
                eclipseData: eclipseData,
            });
        }
    }
);

export const setBranchCookie = createAsyncThunk("user/setBranchCookie", async (data: setBranchType, thunkAPI) => {

    try {
        var branch: number = data.branch;
        return thunkAPI.fulfillWithValue({
            branch: branch,
            message: 'COMPLETED setBranchCookie',
            code: 0,
        });
    } catch (err: any) {
        console.log('ERROR:  setBranchCookie: ', err);

        return thunkAPI.rejectWithValue({
            message:
                "Error setting branch. Please let us know so we can fix it.",
            code: err.response.status,
        });
    }

});

export const setUsernameCookie = createAsyncThunk("user/setUsernameCookie", async (data: setUsernameType, thunkAPI) => {

    try {
        var username: string = data.username;
        return thunkAPI.fulfillWithValue({
            username: username,
            message: 'COMPLETED setUsernameCookie',
            code: 0,
        });
    } catch (err: any) {
        console.log('ERROR:  setUsernameCookie: ', err);

        return thunkAPI.rejectWithValue({
            message:
                "Error setting username. Please let us know so we can fix it.",
            code: err.response.status,
        });
    }

});

function sortList(dataManf: any, dataModel: any, dataDescription: any, dataEclipse: any) {
    var newArray: any = [];
    for (var i = 0; i < dataManf.length; i++) {
        let newEntry: any = dataManf[i] + "|" + dataModel[i] + ' |' + dataDescription[i] + '|' + dataEclipse[i];
        newArray.push(newEntry);
    }
    var sortedArray = newArray.sort();
    var newManf: any = [];
    var newDesc: any = [];
    var newModel: any = [];
    var newEclipse: any = [];

    for (var i = 0; i < sortedArray.length; i++) {
        let elementArray: any = sortedArray[i].split('|')
        let manf = elementArray[0]
        let model = elementArray[1]
        let desc = elementArray[2]
        let eclipseid = elementArray[3]
        newManf.push(manf);
        newDesc.push(desc);
        newModel.push(model);
        newEclipse.push(eclipseid)
    }
    return { newManf, newDesc, newModel, newEclipse }
}

//Get information from the uploaded schedule of localTest is set to false and resturns mockFirstRender if set to true
async function getScheduleData(
    FRONT_DEVELOPMENT: any,
    renderCounter: any,
    fileData: any,
    parsing_strategy: any,
    branch: any,
    localTest: any,
) {
    var process_connection = FRONT_DEVELOPMENT ? "https://scouttest.reece.com/api" : `${process.env.REACT_APP_API_URL}`;
    var response: any = '';
    var selected_branch = GetBranchCookie();
    var username = GetUsernameCookie();
    //console.log("sendFile/process_connection:", process_connection)

    if (localTest) {
        if (renderCounter === 0) {
            //response = mockFirstRender;
            response = '';
        } else if (renderCounter === 1) {
            //response = mockSecondRender;
            response = '';
        } else {
            //response = mockThirdRender;
            response = '';
        }
        console.log(`\n\n runing sendFile in localtest ...`);
    } else {
        response = await axios.post(
            `${process_connection}/blueprint`, fileData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    process: true,
                    ocr_engine: "textract",
                    parsing_strategy: parsing_strategy,
                    ...(selected_branch && { branch: selected_branch }),
                    ...(username && { user: username }),
                },
            }
        );
    }
    if (response == '') {
        throw new Error("REMEMBER TO CHANGE DATA IN LOCAL TEST");
    } else {
        return response;
    }
}

function expandNotes(dataFrame: any) {
    //Transform Data to display Note section at the end of each tag section
    let notesObject: { [index: string]: any } = {};
    let previoustag: string = '';
    var lastPosition = 0;
    var tag_id = '';
    //Identify tags and collect notes 
    for (var i = 0; i < dataFrame.length; i++) {
        var element = dataFrame[i];
        var dataId = element['id'];
        var tag = element['tag'];

        if (tag === '') {
            let notesElement = element['NOTES'];
            //if(notesElement !== ''){
            notesObject[previoustag]['notes'] = notesObject[previoustag]['notes'].concat(notesElement);
            //}
            lastPosition = i
        } else {
            if (i !== 0) {
                lastPosition = i;
                notesObject[previoustag] = {
                    'notes': notesObject[previoustag]['notes'], //Doesn't change
                    'position': lastPosition
                };
            }
            if (notesObject[tag] === undefined) {
                tag_id = tag + "_" + String(lastPosition)
                notesObject[tag_id] = { // Initialize 
                    'notes': [],
                    'position': 0
                };
                previoustag = tag_id;
            } else {


            }

        }
    }
    notesObject[previoustag] = {
        'notes': notesObject[previoustag]['notes'], //Doesn't change
        'position': lastPosition + 1 //LAST ELEMENT
    };
    return notesObject
}

function insertNotes(responseOrdered: any, notesObject: any) {
    //Identify tags and collect notes 
    var indexTag = '';
    var newData = [];
    var indexNewData = 0;
    var position: any = 0;
    var element: any = {};
    var notes: string[] = [''];
    let dataFrame = responseOrdered;
    let tag = '';

    for (var j = 0; j < dataFrame.length + 1; j++) {
        if (j < dataFrame.length) {
            element = dataFrame[j];
            tag = element['tag'];
            var noteContent = '';
        }

        if (j !== 0 && j === position) {
            for (var l = 0; l < notes.length; l++) {
                noteContent = notes[l];
                if (noteContent !== '') {
                    var notesText = "Note: " + noteContent;
                    var noteRow = {
                        id: indexNewData,
                        tag: "",
                        manufacturer: [""],
                        model: "",
                        eclipse_id: "",
                        description: [notesText],
                        descriptionIndex: 0

                    };
                    newData = pushElement(newData, noteRow)
                    indexNewData += 1;
                }
            }
        } else {
            if (indexNewData !== j) {
                let elementReindexed = Object.assign({}, element);
                elementReindexed['id'] = indexNewData;
                if (element['tag'] === '') {
                    newData = pushElement(newData, elementReindexed)
                    indexNewData += 1;
                }
            } else {
                if (j !== 0) {
                    if (element['tag'] === '') {
                        newData = pushElement(newData, element)
                        indexNewData += 1;
                    }
                }
            }
        }

        if (tag !== '') {
            indexTag = tag + "_" + String(position);  //TODO: REVIEW CODE
            position = notesObject[indexTag]['position'];
            notes = notesObject[indexTag]['notes'];

            let elementReindexed = Object.assign({}, element);
            elementReindexed['id'] = indexNewData;
            elementReindexed['quantity'] = 0;
            newData = pushElement(newData, elementReindexed)
            indexNewData += 1;
        }
    }
    return newData;
}

function pushElement(data: any, element: any) {
    let elementReindexed = Object.assign({}, element);
    if (element['tag'] !== '') {
        elementReindexed['quantity'] = ''
        data.push(elementReindexed);

    } else {
        data.push(elementReindexed);
    }
    return data
}

function sortModelDescription(data: any) {
    for (var i = 0; i < data.length; i++) {
        var dataEntry = data[i];
        var dataModel = dataEntry['model'];
        var dataDescription = dataEntry['description'];
        var dataUnsorted = [...dataModel]
        var dataManf = dataEntry['manufacturer']
        var dataEclipse = dataEntry['eclipse_id']


        if (dataModel.length > 1) {
            //TODO: Complete sorting function
            var { newManf, newDesc, newModel, newEclipse } = sortList(dataManf, dataModel, dataDescription, dataEclipse);
            data[i]['model'] = newModel;
            data[i]['description'] = newDesc;
            data[i]['manufacturer'] = newManf;
            data[i]['eclipse_id'] = newEclipse;
        }
        data[i]['id'] = i;
        data[i]['index'] = i;

    }
    return data
}

export const sendFile = createAsyncThunk("user/sendFile", async (data: sendFileType, thunkAPI) => {
    var FRONT_DEVELOPMENT = true;
    var renderCounter = data.renderCounter;
    var fileData = data.fileData
    var parsing_strategy = data.parsing_strategy
    var branch = data.branch;
    var localTest = data.localTest;

    try {
        //GET DATA
        var response: any = await getScheduleData(
            FRONT_DEVELOPMENT,
            renderCounter,
            fileData,
            parsing_strategy,
            branch,
            localTest,
        );
        console.log('getScheduleData/response', response)
        //VALIDATION
        if (response.data.data.length === 0) {
            return thunkAPI.rejectWithValue({
                message: "No Plumbing Fixture Schedules Detected.",
                code: response.data.return_code.code,
            });
        }
        if (response.data.type === "ERROR") {
            return thunkAPI.rejectWithValue({
                message: "Error processing blueprint. Please let us know so we can fix it.",
                code: response.data.return_code.code,
            });
        }
        //DATA TRANSFORMATION
        var responseOrdered: any = sortModelDescription(response.data.data);
        //console.log(`senFile/responseOrdered: ${JSON.stringify(responseOrdered)}`);
        var notesObject: any = expandNotes(responseOrdered);
        var responseNotes: any = insertNotes(responseOrdered, notesObject);
        var newRenderCounter: number = renderCounter + 1;
        if (localTest) {
            return thunkAPI.fulfillWithValue({
                message: "local test",
                code: "code local test",
                csvData: 'csvData local test',
                data: responseNotes,
                renderCounter: newRenderCounter,
            });

        } else {
            return thunkAPI.fulfillWithValue({
                message: response.data.return_code?.msg,
                code: response.data.return_code?.code,
                csvData: response.data.final_csvs?.textract,
                data: responseNotes,
                renderCounter: newRenderCounter,

            });
        }
    } catch (err: any) {
        console.log(err);
        var message: string = "Error processing blueprint. Please let us know so we can fix it: " + err;
        return thunkAPI.rejectWithValue({
            message: message,
            code: err.response.status,
        });
    }
});

export const sendReport = createAsyncThunk(
    "user/sendReport",
    async (reportData: sendReportDataType, thunkAPI) => {
        const username = String(GetUsernameCookie());
        try {
            const creatingIssueResponse: any = await axios.post(
                `${process.env.REACT_APP_API_URL}/jira`,
                reportData.fileData,
                {
                    params: {
                        error_type: reportData.summary,
                        error_description: reportData.description,
                        user_id: username,
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
        } catch (err: any) {
            console.log(err);

            return thunkAPI.rejectWithValue({
                message: err.message,
                code: err.response.status,
            });
        }
    }
);

export const getMetrics = createAsyncThunk(
    "user/getMetrics",
    async (
        queryData: {
            year?: number;
            month?: number;
        },
        thunkAPI
    ) => {
        var FRONT_DEVELOPMENT = true;
        var process_connection = FRONT_DEVELOPMENT ? "https://scouttest.reece.com/api" : `${process.env.REACT_APP_API_URL}`;
        try {
            const metricsResponse: any = await axios.get(
                `${process_connection}/query`,
                {
                    params: queryData,
                }
            );
            console.log(metricsResponse.data);

            return metricsResponse.data;
        } catch (err: any) {
            console.log(err);

            return thunkAPI.rejectWithValue({
                message: err.message,
                code: err.response.status,
            });
        }
    }
);


export const transformSearch = (search: any) => {
    const spaceTransform = search.replace(" ", "%20")
    const capitalTransform = spaceTransform.toUpperCase();
    return capitalTransform;
}


export const categorizeText = createAsyncThunk(
    "user/categorizeText",
    async (searchData: string, thunkAPI) => {
        var FRONT_DEVELOPMENT = true;
        var process_connection = FRONT_DEVELOPMENT ? "https://scouttest.reece.com/api" : `${process.env.REACT_APP_API_URL}`;
        try {
            const { data } = await axios.get(
                `${process_connection}/categorize_text`,
                {
                    params: {
                        text: searchData,
                    },
                }
            );

            return data;
        } catch (err: any) {
            console.error(err);

            return thunkAPI.rejectWithValue({
                message: err.message,
                code: err.response.status,
            });
        }
    }
);

const handleSearchChange = (
    dataPost: any,
    rows: any,
    id: any,
    nonstock: boolean = false
) => {

    var searchDescription = [];
    var searchModel = [];
    var searchEclipse = [];
    var searchManufacturer = [];
    if (nonstock) {
        const item = processNsString(dataPost)[1];
        searchDescription.push("Non Stock Item");
        searchModel.push("Non Stock Item");
        searchEclipse.push("0");
        searchManufacturer.push("Non Stock Item");
    } else {
        for (const [index, element] of dataPost.entries()) {
            searchDescription.push(element["name"]);
            var eclipse_complete: any = element["id"]
            var eclipse_id: string = eclipse_complete.replace('MSC-', '');
            searchEclipse.push(eclipse_id);
            searchManufacturer.push(element['manufacturerName']);
            searchModel.push(element["manufacturerNumber"]);
        }
    }

    var newRows = [...rows];
    var { newManf, newDesc, newModel, newEclipse } = sortList(searchManufacturer, searchModel, searchDescription, searchEclipse);
    if (id > rows.length) {
        newRows[id] = {};
    }

    newRows[id] = {
        id: id,
        quantity: 1,
        manufacturer: newManf,
        descriptionIndex: 0,
        description: newDesc,
        model: newModel,
        eclipse_id: newEclipse, //TODO: Recreate sorting function and add eclipse ID 
        tag: "",
    };
    return newRows
};

function processNsString(input: string): [boolean, string] {
    const trimmedInput = input.trim();
    if (trimmedInput.startsWith("!ns") || trimmedInput.startsWith("%20!ns")) {
        console.log("Nonstock item detected")
        return [false, input.substring(4).trim() || "Non-Stock Item"];
    } else {
        return [true, input];
    }
}


export const getPost = createAsyncThunk("user/getPost", async (data: sendPostType, thunkAPI) => {

    var [sendToPost, searchData] = processNsString(data.searchData);
    var searchDataDecode = searchData.replace(/%20/g, "");
    if (!sendToPost) {
        return thunkAPI.fulfillWithValue({
            data: handleSearchChange(searchDataDecode, data.data, data.id, true),
            message: 'search completed, nonstock item',
        });

    } else if (searchDataDecode !== '' && sendToPost) {
        var searchTransform = transformSearch(data.searchData);
        var dataStart = data.data;
        var id = data.id;
        var localTest = data.localTest
        var dataPost: any = '';
        var dataResults: any = '';

        var FRONT_DEVELOPMENT = true;
        var api_endpoint = FRONT_DEVELOPMENT ? "https://scouttest.reece.com/api" : `${process.env.REACT_APP_API_URL}`;
        var process_connection = `${api_endpoint}/lookupProduct?text=${searchTransform}`;

        if (localTest) {
            //dataPost =  mockPost;
            dataPost = '';
            var newRows = handleSearchChange(
                dataPost,
                dataStart,
                id
            );
            return thunkAPI.fulfillWithValue({
                data: newRows,
                message: 'search completed'
            });
        } else {
            try {
                dataPost = await axios.get(`${process_connection}`);
                dataPost = dataPost.data;

                if (dataPost['data']['searchProduct'] != null) {

                    dataResults = dataPost['data']['searchProduct']['products'];
                    newRows = handleSearchChange(
                        dataResults,
                        dataStart,
                        id
                    );

                    return thunkAPI.fulfillWithValue({
                        data: newRows,
                        message: 'search completed'

                    });
                } else {
                    return thunkAPI.fulfillWithValue({
                        data: dataStart,
                        message: 'search failed',
                    });
                }

            } catch (err: any) {
                console.error(err);
                return thunkAPI.rejectWithValue({
                    message: err.message,
                    code: err.response.status,
                });
            }
        }

    } else {
        return thunkAPI.rejectWithValue({
            message: 'Empty String',
            code: 500,
        });
    }
});

export const resetData = createAsyncThunk("user/resetData", async (data: resetDataType, thunkAPI) => {
    try {
        if (data.oldData.length > 0) {
            return thunkAPI.fulfillWithValue({
                message: "RESET DATA",
                data: [],
                oldData: data.oldData,
                eclipseLabel: "",
                loading: false,
            });
        } else {
            return thunkAPI.fulfillWithValue({
                message: "NO PREVIOUS DATA",
                data: [],
            });
        }
    } catch (err: any) {
        console.error(err);
        return thunkAPI.rejectWithValue({
            message: err.message,
            code: err.response.status,
        });
    }
}
);

export const resetValues = createAsyncThunk("user/resetData", async (data: resetDataType, thunkAPI) => {
    var emptyRow = {
        id: 0,
        tag: "",
        insert: "",
        quantity: "",
        manufacturer: "",
        model: [],
        eclipse_id: "",
        description: "",
        changes: "",
    };

    try {
        if (data.oldData.length > 0) {
            var newData = [];

            for (var i = 0; i <= data.oldData.length; i++) {
                var nextRow = Object.assign({}, emptyRow);
                nextRow['id'] = i;
                newData.push(nextRow)
            }
            return thunkAPI.fulfillWithValue({
                message: "RESET VALUES",
                data: newData,
                oldData: data.oldData,
            });
        } else {
            return thunkAPI.fulfillWithValue({
                message: "RESET VALUES, NO PREVIOUS DATA",
                data: [],
            });
        }

    } catch (err: any) {
        console.error(err);
        return thunkAPI.rejectWithValue({
            message: err.message,
            code: err.response.status,
        });
    }
}
);


export const insertData = createAsyncThunk("user/insertData", async (data: insertDataType, thunkAPI) => {

    try {
        return thunkAPI.fulfillWithValue({
            message: "INSERT DATA",
            data: data.insertData,
            oldData: data.oldData,
            renderCounter: data.renderCounter + 1,
        });
    } catch (err: any) {
        console.error(err);
        return thunkAPI.rejectWithValue({
            message: err.message,
            code: err.response.status,
        });
    }

});

